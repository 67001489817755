import React from 'react';
import { useTranslation } from 'react-i18next';

import LogoBeinSports from '../../images/customers/white/Logo-bein-sport.png';
import LogoCanal from '../../images/customers/white/Logo-canal.png';
import LogoComcast from '../../images/customers/white/Logo-comcast.png';
import LogoCommscope from '../../images/customers/white/Logo-commscope.png';
import LogoOrange from '../../images/customers/white/Logo-orange.png';
import LogoPeacock from '../../images/customers/white/Logo-peacock.png';
import LogoVerizon from '../../images/customers/white/Logo-verizon.png';
import LogoVodafone from '../../images/customers/white/Logo-vodafone.png';

import newHomeStyles from '../../pages/styles/newHome.module.scss';
import styles from './styles/customers.module.scss';

const CUSTOMERS = [
    {
        name: 'beIN Sports',
        logo: LogoBeinSports,
        additionalClass: styles.sizeDownPictureM,
    }, {
        name: 'Canal+',
        logo: LogoCanal,
        additionalClass: styles.sizeDownPictureXL,
    }, {
        name: 'Comcast',
        logo: LogoComcast,
        additionalClass: styles.sizeUpPictureM,
    }, {
        name: 'Commscope',
        logo: LogoCommscope,
        additionalClass: styles.sizeDownPictureXL,
    }, {
        name: 'Orange',
        logo: LogoOrange,
    }, {
        name: 'Peacock',
        logo: LogoPeacock,
        additionalClass: styles.sizeUpPictureM,
    }, {
        name: 'Verizon',
        logo: LogoVerizon,
        additionalClass: styles.sizeDownPictureM,
    }, {
        name: 'Vodafone',
        logo: LogoVodafone,
        additionalClass: styles.sizeUpPictureM,
    }
];

const Customers = () => {
    const { t } = useTranslation();

    return (
        <section id={newHomeStyles.trustedBySection} className={styles.trustedBy}>
            <h2 className={styles.pageSectionTitle}>{t('siteMetadata.home2023.powering')}</h2>
            <div className={styles.imageContainer}>
                {CUSTOMERS.map(customer =>
                    <img src={customer.logo} alt={customer.name} key={customer.name} className={customer.additionalClass} />)}
            </div>
        </section>
    );
};

export default Customers;
